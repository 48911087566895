import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';
import { v4 as uuid } from 'uuid';

// Application
import {
  SearchISOCountriesQuery,
} from '@/modules/my-investment/catalogs/allianz/iso-country/application/queries';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsProfilingPersonalInformationViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_ISO_COUNTRY_QUERY)
  private readonly searchISOCountriesQuery!: SearchISOCountriesQuery;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly getPersonQueryService!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.profiling.personal-information';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  small_screen: null | boolean = null;

  form_validity = false;

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'tax_information';

  investment_provider_id = '';

  is_loading = true;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      has_tax_residence_outside_mexico: false,
      american_citizen: false,
      tax_id: '',
      birth_country: {
        codPais: null,
        descPais: null,
      },
      residence_country: {
        codPais: null,
        descPais: null,
      },
      foreign_residence_country: {
        codPais: null,
        descPais: null,
      },
    },
  };

  inputs = {
    tax_residence_outside_mexico: '',
    usa_citizen: '',
    tax_id: '',
    foreign_residence_country: {
      codPais: null,
      descPais: null,
    },
    birth_country: {
      codPais: null,
      descPais: null,
    },
    residence_country: {
      codPais: null,
      descPais: null,
    },
  };

  rules = {
    tax_residence_outside_mexico: [requiredRule],
    usa_citizen: [requiredRule],
    tax_id: [requiredRule],
    residence_country: [
      (value: string) => requiredRuleByKey(value, 'codPais'),
    ],
    foreign_residence_country: [
      (value: string) => requiredRuleByKey(value, 'codPais'),
    ],
    birth_country: [
      (value: string) => requiredRuleByKey(value, 'codPais'),
    ],
  };

  get isoCountries() {
    return this.searchISOCountriesQuery.execute();
  }

  get userName() {
    return this.getPersonQueryService.execute().name;
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.tax_residence_outside_mexico = this
      .on_boarding_step.payload.has_tax_residence_outside_mexico === true ? 'yes' : 'no';
    this.inputs.foreign_residence_country = this.on_boarding_step.payload.foreign_residence_country;
    this.inputs.usa_citizen = this.on_boarding_step.payload.american_citizen === true
      ? 'yes' : 'no';
    this.inputs.tax_id = this.on_boarding_step.payload.tax_id;
    this.inputs.birth_country = this.on_boarding_step.payload.birth_country;
    this.inputs.residence_country = this.on_boarding_step.payload.residence_country;
  }

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload.has_tax_residence_outside_mexico = this.inputs.tax_residence_outside_mexico === 'yes';
      this.on_boarding_step.payload.american_citizen = this.inputs.usa_citizen === 'yes';
      this.on_boarding_step.payload.tax_id = this.inputs.tax_id;
      this.on_boarding_step.payload.birth_country = this.inputs.birth_country;
      this.on_boarding_step.payload.residence_country = this.inputs.residence_country;
      // eslint-disable-next-line max-len
      this.on_boarding_step.payload.foreign_residence_country = this.inputs.foreign_residence_country;
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    await this.verifyStep(steps);

    this.is_loading = false;
  };
}
